var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-file-input", {
    ref: "fileInput",
    attrs: {
      required: _vm.required,
      value: _vm.file,
      "show-size": _vm.showSize,
      placeholder: _vm.placeholder || "Upload File",
      hint: _vm.hint,
      "persistent-hint": _vm.persistentHint,
      disabled: _vm.readonly,
      hideInput: _vm.hideInput,
    },
    on: { change: _vm.handleFileChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }